var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"page-title"},[_vm._v(" Invoices "),(_vm.user.can('invoice', 'edit'))?_c('IconAction',{attrs:{"title":"New Invoice","icon":"plus","size":"sm"},on:{"click":_vm.createInvoice}}):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"xs":"12","lg":"6"}}),_c('b-col',{attrs:{"xs":"12","lg":"6"}},[_c('SearchInput',{attrs:{"settings":_vm.query_settings},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},[_c('Widget',{attrs:{"title":"","body-class":"widget-table-overflow","columns":"","column_fields":_vm.fields,"refresh":""},on:{"refresh":function($event){return _vm.dataTable.refresh()}}},[_c('data-table',{ref:"dataTable",attrs:{"id":"invoice-table","rows":_vm.invoices,"query":_vm.query,"records":_vm.records,"loading":_vm.loading,"fields":_vm.show_fields,"checked":_vm.selected,"field-filters":_vm.fieldFilters},on:{"update:checked":function($event){_vm.selected=$event},"update:fieldFilters":function($event){_vm.fieldFilters=$event},"update:field-filters":function($event){_vm.fieldFilters=$event}},scopedSlots:_vm._u([{key:"cell(total_impressions)",fn:function(data){return [(data.value == 0)?_c('span',[_vm._v("N/A")]):_c('span',[_vm._v(_vm._s(_vm._f("separator")(data.value)))])]}},{key:"cell(status)",fn:function(data){return [_c('InvoiceBadgeStatus',{attrs:{"invoice":data.item}})]}},{key:"cell(action)",fn:function(data){return [(
                _vm.user.can('user', 'edit') &&
                  data.item.status != 1 &&
                  data.item.status != 2
              )?_c('IconAction',{attrs:{"title":"Edit Invoice","icon":"pencil"},on:{"click":function($event){return _vm.editInvoice(data.item.id)}}}):_vm._e(),(
                _vm.user.can('user', 'delete') &&
                  data.item.status != 1 &&
                  data.item.status != 2
              )?_c('IconAction',{attrs:{"title":"Delete Invoice","icon":"trash"},on:{"click":function($event){return _vm.confirmDelete(data.item)}}}):_vm._e(),(_vm.user.can('user', 'edit'))?_c('IconAction',{attrs:{"title":"View on Freshbooks","icon":"file"},on:{"click":function($event){return _vm.openFreshbooks(data.item)}}}):_vm._e(),(_vm.user.can('user', 'delete'))?_c('IconAction',{attrs:{"title":"Send Invoice","icon":"send"},on:{"click":function($event){return _vm.sendInvoice(data.item)}}}):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }