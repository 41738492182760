

























































































import { Component, Ref } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import Closeout from '@/models/Closeout'
import Invoice from '@/models/Invoice'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import IconAction from '@/components/IconAction/IconAction.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import DataTable from '@/components/DataTable/index.vue'
import InvoiceBadgeStatus from './Components/InvoiceBadgeStatus.vue'

import home_table_options from './home-table-fields'

@Component({
  components: {
    Widget,
    InvoiceBadgeStatus,
    IconAction,
    SearchInput,
    DataTable,
  },
})
export default class InvoiceHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public invoice: Invoice = new Invoice()

  public sort_by: string = 'created_at'

  public sort_desc: boolean = true

  public page_size: number = 50

  public page: number = 1

  public records: number = 0

  public loading: boolean = false

  public ready: boolean = false

  public selected: string[] = []

  public fieldFilters: any = {}

  public query: string[] = []

  public fields: Array<any> = []

  public modal: any = {
    delete: false,
  }

  public query_settings: any = {
    company_fields: [
      {
        name: 'partner',
        key: 'partner_id',
      },
    ],
    custom_fields: [
      {
        name: 'status:draft',
        value: 'is:draft',
      },
      {
        name: 'status:paid',
        value: 'is:paid',
      },
      {
        name: 'status:overdue',
        value: 'is:overdue',
      },
      {
        name: 'status:sent',
        value: 'is:sent',
      },
      {
        name: 'status:partially_paid',
        value: 'is:partially_paid',
      },
    ],
  }

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public invoices(context: any) {
    this.loading = true
    return Invoice.paginate({
      page_size: this.page_size,
      page: this.page,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: context.filter,
    }).then(result => {
      this.records = result.records
      this.loading = false
      this.ready = true

      return result.data
    })
  }

  public confirmDelete(invoice: Invoice) {
    this.invoice = invoice
    this.modal.delete = true
  }

  public deleteInvoice() {
    this.invoice.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public editInvoice(id: string) {
    this.$router.push(`/app/invoice/${id}`)
  }

  public openFreshbooks(invoice: Invoice) {
    if (invoice.external_id) {
      window.open(
        `https://my.freshbooks.com/#/invoice/${
          invoice.company!.invoicing_account_id
        }-${invoice.external_id}`,
        '_blank',
      )
    }
  }

  public sendInvoice(invoice: Invoice) {
    this.invoice = invoice
    this.modal.send = true
  }

  public confirmSendInvoice() {
    this.invoice.send().then(() => {
      this.dataTable.refresh()
    })
  }

  public createInvoice() {
    const has_closeouts = Closeout.module.data.some(
      (c: Closeout) => c.id && !c.invoice_id,
    )
    if (has_closeouts) {
      this.$router.push({ name: 'Invoice' })
    } else {
      WebMessage.error(
        'No pending closeouts, please create a closeout first before generate the invoice.',
      )
    }
  }

  public created() {
    this.records = this.invoices.length
  }

  public mounted() {
    this.fields = home_table_options
  }
}
