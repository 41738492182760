import DataTableField from '@/components/DataTable/DataTableField'

export default [
  new DataTableField('checkbox', '', 'checkbox', true, false),
  {
    key: 'number',
    label: '#',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    thStyle: { width: '10%' },
  },
  {
    key: 'partner',
    label: 'Partner',
    sortable: true,
    class: 'text-center align-middle',
    type: 'object',
    object: {
      key: 'partner',
      property: 'name',
    },
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'total_impressions',
    label: 'Impressions',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'total',
    label: 'Total',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'due_at',
    label: 'Due At',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'updated_at',
    label: 'Update At',
    sortable: true,
    class: 'text-center align-middle',
    show: false,
    thClass: 'align-middle text-center',
    type: 'date',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
    thClass: 'align-middle text-center',
  },
  {
    key: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    thClass: 'align-middle text-center',
  },
]
