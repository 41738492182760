









































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Invoice from '../../../models/Invoice'

@Component({
  components: {},
})
export default class InvoicePrintView extends Vue {
  @Prop()
  public invoice!: Invoice;
}
