




















































































































































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import DatePicker from '@/components/DatePicker/DatePicker.vue'
import SelectPicker from '@/components/SelectPicker/SelectPicker.vue'
import Invoice from '@/models/Invoice'
import Company from '@/models/Company'
import Closeout from '@/models/Closeout'
import ViewModel from '@/models/ViewModel'
import WebMessage from '@/models/WebMessage'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import CompanyPicker from '@/components/CompanyPicker/CompanyPicker.vue'
import InvoicePrintView from './Components/InvoicePrintView.vue'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    Widget,
    DatePicker,
    SelectPicker,
    InvoicePrintView,
    FormWizard,
    TabContent,
    FormInput,
    CompanyPicker,
  },
})
export default class InvoiceEdit extends ViewModel {
  @Prop()
  public id!: string | null

  public invoice: Invoice = new Invoice()

  public media_ocean: boolean = false

  public closeouts: Closeout[] = []

  public step: number = 1

  public hide_company_select: boolean = false

  public busy = true

  public title: string = 'Create Invoice'

  public target_partner: string = ''

  public select_all: boolean = false

  public modal: any = {
    create: false,
  }

  public get avaliable_closeouts() {
    return this.closeouts.filter(
      (c: Closeout) => c.partner_id == this.invoice.partner_id,
    )
  }

  public get select_all_indeterminate() {
    return (
      this.closeouts.some((c: Closeout) => c.selected)
      && this.closeouts.some((c: Closeout) => !c.selected)
    )
  }

  @Watch('select_all')
  public onSelectAll() {
    this.closeouts.forEach((c: Closeout) => {
      c.selected = this.select_all
    })
  }

  public rowClickHandler(item: Closeout) {
    this.closeouts.forEach((c: Closeout) => {
      if (c.key == item.key) c.selected = !c.selected
    })
    if (!this.closeouts.some((c: Closeout) => !c.selected)) this.select_all = true
  }

  public fields: any = [
    {
      key: 'check',
    },
    {
      key: 'publisher',
      label: 'Publisher',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'date',
      label: 'Date',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'impressions_advertiser',
      label: 'Impressions',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'rate_advertiser',
      label: 'Rate',
      sortable: true,
      class: 'text-center',
    },
    {
      key: 'revenues',
      label: 'Total',
      sortable: true,
      class: 'text-center',
    },
  ]

  public updateProgress(prevIndex: number, nextIndex: number) {
    if (nextIndex >= 0) {
      Vue.set(this, 'step', nextIndex + 1)
    }
  }

  public validateStep() {
    this.invoice._closeouts = this.closeouts.filter((c: Closeout) => c.selected)
    this.invoice.updateTotals()
    if (this.step === 2 && this.invoice.closeouts.length <= 0) {
      WebMessage.error('Please select at least 1 item.')
      return false
    }

    return true
  }

  public onSubmit() {
    if (!this.invoice.partner!.isBillingInformationComplete) {
      WebMessage.error(
        'The selected partner does not have all the necessary billing information. Please update the billing information before generating the invoice.',
      )

      return
    }

    if (!this.invoice.partner!.invoicing_id) {
      this.modal.create = true
    } else {
      this.executeSubmit()
    }
  }

  public executeSubmit() {
    this.invoice
      .save()
      .then(response => {
        this.loading = false
        if (response.status == 200) {
          this.$router.push({ name: 'Invoices' })
        }
      })
      .catch(() => {
        this.loading = false
      })
  }

  public reviewBillingInformation() {
    this.$router.push(`/app/company/${this.invoice.partner_id}`)
  }

  public cancel() {
    this.$router.push({ name: 'Invoices' })
  }

  @Watch('target_partner')
  public onPartnerChange() {
    Company.get(this.target_partner).then(() => {
      this.invoice.partner_id = this.target_partner
    })
  }

  public created() {
    if (this.id) {
      this.title = 'Edit Invoice'

      Invoice.get(this.id).then(invoice => {
        if (invoice instanceof Invoice) this.invoice = invoice
        else this.newInvoice()
        // merge pending & existing
        Closeout.getPending().then(closeouts => {
          this.closeouts = closeouts.concat(this.invoice.closeouts)

          this.closeouts.forEach((c: Closeout) => {
            if (c.invoice_id == this.id) {
              c.selected = true
            }
          })

          this.hide_company_select = true

          this.busy = false
        })
      })
    } else {
      this.newInvoice()
    }
  }

  public newInvoice() {
    this.invoice.company_id = this.user.company_id
    Closeout.getPending().then(closeouts => {
      this.closeouts = closeouts
      const company_ids = this.closeouts.map((c: Closeout) => c.partner_id)

      this.hide_company_select = company_ids.length == 1

      if (this.hide_company_select) {
        this.invoice.partner_id = company_ids[0]
      }

      if (!this.closeouts.some((c: Closeout) => !c.selected)) this.select_all = true

      this.busy = false
    })
  }
}
